import TheSidebar from "../components/layout/TheSidebar";
import spinner from '../assets/spinner.svg'

export default function Search() {
    return (
        <>
            <TheSidebar></TheSidebar>
            <section id="PageSearch" className="col-9">
                <div className="card mb-3 wrapper-text">
                    <div className="card-header bg-color-primary-2 text-white text-center">جستجو پیشرفته</div>
                    <div className="card-body">
                        <form>
                            <div className="row align-items-end">
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">نوع نمایش<span
                                        className="text-danger ms-1">*</span></label>
                                    <select className="form-select">
                                        <option>1111111111</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">ژانر<span className="text-danger ms-1">*</span></label>
                                    <select className="form-select">
                                        <option>1111111111</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">تاریخ انتشار<span
                                        className="text-danger ms-1">*</span></label>
                                    <select className="form-select" v-model="year">
                                        <option value="2018">2018</option>
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">نمایش بر اساس</label>
                                    <select className="form-select">
                                        <option>324324234</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">میانگین امتیاز</label>
                                    <select className="form-select">
                                        <option>324324234</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <button type="submit" className="btn btn-primary w-100">جستجو</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row wrapper-text">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                carttttttttttt movie
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="d-block m-3 text-center">
                            <img src={spinner} alt="isLoading" width="100" height="100"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}