export default function TheFooter() {
    return (
        <div id="TheFooter" className="container-fluid bg-color-primary-1">
            <div className="container">
                <p className="text-center text-white m-0 p-0">
                    تمام حقوق مادی و معنوی محفوظ است.
                </p>
            </div>
        </div>
    )
}