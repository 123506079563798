import Spinner from "../components/layout/Spinner";
import NoImage from "../assets/no_image.jpg";
import NoCover from "../assets/no_cover.jpg";
import {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {apiKey, lang, imageSizeMovie, translatePersian} from "../mixins";
import apiAxios from "../Api";

const PageSingleMovie = () => {

    const [movie, setMovie] = useState([]);
    const [imageMovie, setImageMovie] = useState([]);
    const movie_id = useParams();

    useEffect(async () => {
        let query = `/movie/${movie_id.id}?api_key=${apiKey}&language=${lang}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data
            setMovie(response);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageSingleMovie getMovie');
        }
    }, [])

    useEffect(async () => {
        let query = `/movie/${movie_id.id}/images?api_key=${apiKey}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data
            setImageMovie(response);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageSingleMovie getImageMovie');
        }
    }, [])

    return (
        <section id="PageSingleMovie" className="col-12 wrapper-text">
            {
                Object.keys(movie).length > 0 ?
                    <div className="card mb-4">
                        <div className="card-header p-0 m-0">
                            {
                                movie.backdrop_path && !movie.adult ?
                                    <img src={imageSizeMovie('original') + movie.backdrop_path}
                                         className="img-fluid w-100" alt={movie.original_title}
                                         title={movie.original_title}/>
                                    :
                                    <img src={NoCover} className="img-fluid w-100" alt={movie.original_title}
                                         title={movie.original_title}/>
                            }
                        </div>
                        <div className="card-body">
                            <h1 className="text-center"> {movie.title} </h1>
                            <p className="text-center"> {movie.overview} </p>
                            <div className="d-flex flex-wrap align-content-center justify-content-between">

                                {
                                    movie.genres && movie.genres.length > 0 ?
                                        <div className="card text-white bg-success m-2 px-2 text-center flex-grow-1">
                                            <div className="card-header">ژانرها</div>
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    {
                                                        movie.genres ? movie.genres.map((genre, index) =>
                                                                <span key={index}>{translatePersian(genre.name)}
                                                                    {
                                                                        index !== Object.keys(movie.genres).length - 1 &&
                                                                        <span>- </span>
                                                                    }
                                                        </span>
                                                        ) : []
                                                    }
                                                </h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                <div className="card text-white bg-info m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">رده سنی</div>
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {movie.adult ? 'بزرگسالان' : 'خانوادگی'}
                                        </h5>
                                    </div>
                                </div>

                                {
                                    movie.production_companies && movie.production_companies.length > 0 ?
                                        <div
                                            className="card text-white bg-color-primary-3 m-2 px-2 text-center flex-grow-1">
                                            <div className="card-header">کمپانی سازنده</div>
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    {
                                                        movie.production_companies ? movie.production_companies.map((production_companie, index) =>
                                                                <span key={index}>{production_companie.name}
                                                                    {
                                                                        index !== Object.keys(movie.production_companies).length - 1 &&
                                                                        <span>- </span>
                                                                    }
                                                        </span>
                                                        ) : []
                                                    }
                                                </h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                <div className="card text-white bg-primary m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">تاریخ انتشار</div>
                                    <div className="card-body">
                                        <h5 className="card-title">{movie.release_date}</h5>
                                    </div>
                                </div>
                                {
                                    movie.production_countries && movie.production_countries.length > 0 ?
                                        <div
                                            className="card text-white bg-color-primary-4 m-2 px-2 text-center flex-grow-1">
                                            <div className="card-header">کشور سازنده</div>
                                            <div className="card-body">
                                                <h5 className="card-title"> {movie.production_countries[0].name} </h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                <div className="card text-white bg-black m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">تعداد رای</div>
                                    <div className="card-body">
                                        <h5 className="card-title"> {movie.vote_count} رای</h5>
                                    </div>
                                </div>
                                <div className="card text-white bg-danger m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">میانگین امتیاز</div>
                                    <div className="card-body">
                                        <h5 className="card-title"> {movie.vote_average} از 10</h5>
                                    </div>
                                </div>
                                <div className="card text-white bg-dark m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">تاریخ اکران</div>
                                    <div className="card-body">
                                        <h5 className="card-title"> {movie.release_date} </h5>
                                    </div>
                                </div>
                                <div className="card text-white bg-color-primary-2 m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">شناسه IMBD</div>
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {
                                                movie.homepage ?
                                                    <a href={movie.homepage} target="_blank" className="text-white">
                                                        {movie.imdb_id}
                                                    </a>
                                                    :
                                                    <p className="text-white m-0"> {movie.imdb_id} </p>
                                            }
                                        </h5>
                                    </div>
                                </div>
                                {
                                    movie.budget ?
                                        <div className="card text-white bg-warning m-2 px-2 text-center flex-grow-1">
                                            <div className="card-header">بودجه تولید</div>
                                            <div className="card-body">
                                                <h5 className="card-title">$ {movie.budget} </h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner/>
            }

            {
                !movie.adult && imageMovie.backdrops && Object.keys(imageMovie.backdrops).length > 0 ?
                    <div className="card">
                        <div className="card-header bg-success">
                            <h4 className="text-center text-white my-2">تصاویر {movie.title}</h4>
                        </div>
                        <div className="card-body">
                            <div className="row border rounded-1 mb-2 p-2 align-items-center">
                                {
                                    imageMovie.backdrops ? imageMovie.backdrops.map((photo, index) =>
                                        <div className="col-md-6 my-1" key={index}>
                                            {
                                                photo.file_path ?
                                                    <img src={imageSizeMovie('w780') + photo.file_path}
                                                         className="img-fluid w-100" alt={movie.original_title}
                                                         title={movie.original_title}/>
                                                    :
                                                    <img src={NoCover} className="img-fluid w-100"
                                                         alt={movie.original_title} title={movie.original_title}/>
                                            }
                                        </div>
                                    ) : []
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner/>
            }

            {
                !movie.adult && imageMovie.posters && Object.keys(imageMovie.posters).length > 0 ?
                    <div className="card">
                        <div className="card-header bg-success">
                            <h4 className="text-center text-white my-2">پوستر {movie.title}</h4>
                        </div>
                        <div className="card-body">
                            <div className="row border rounded-1 mb-2 p-2 align-items-center">
                                {
                                    imageMovie.posters ? imageMovie.posters.map((photo, index) =>
                                        <div className="col-md-3 col-lg-2 my-1" key={index}>
                                            {
                                                photo.file_path ?
                                                    <img src={imageSizeMovie('w780') + photo.file_path}
                                                         className="img-fluid w-100" alt={movie.original_title}
                                                         title={movie.original_title}/>
                                                    :
                                                    <img src={NoCover} className="img-fluid w-100"
                                                         alt={movie.original_title} title={movie.original_title}/>
                                            }
                                        </div>
                                    ) : []
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner/>
            }
        </section>
    )
}
export default PageSingleMovie

//
// import {Component} from 'react';
//
// export default class PageSingleMovie extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             movie: []
//         }
//     }
//
//     componentDidMount() {
//         this.getMovie()
//     }
//
//     getMovie = async () => {
//         const movie_id = 766907;
//         let query = `/movie/${movie_id}?api_key=${apiKey}&language=${lang}`
//         try {
//             const request = await apiAxios.get(query)
//             const response = request.data
//             this.setState({
//                 movie: response
//             });
//         } catch (error) {
//             console.log(error)
//             console.log('error in catch fetchData PageSingleMovie getMovie');
//         }
//     }
//
//     render() {
//         return (
//             <section id="PageSingleMovie" className="col-12 wrapper-text">
//                 {
//                     this.state.movie.genres ? this.state.movie.genres.map((genre) =>
//                     <span>{genre.name}</span>
//                     ) : []
//                 }
//
//                 {
//                     !this.state.movie.length > 0 ?
//                         <div className="card mb-4">
//                             <div className="card-header p-0 m-0">
//                                 {
//                                     this.state.movie.backdrop_path && !this.state.movie.adult ?
//                                         <img src={imageSizeMovie('original') + this.state.movie.backdrop_path}
//                                              className="img-fluid w-100" alt={this.state.movie.original_title}
//                                              title={this.state.movie.original_title}/>
//                                         :
//                                         <img src={NoImage} className="img-fluid w-100"
//                                              alt={this.state.movie.original_title}
//                                              title={this.state.movie.original_title}/>
//                                 }
//                             </div>
//                             <div className="card-body">
//                                 <h1 className="text-center"> {this.state.movie.title} </h1>
//                                 <p className="text-center"> {this.state.movie.overview} </p>
//                                 <div className="d-flex flex-wrap align-content-center justify-content-between">
//                                     {/*{*/}
//                                     {/*    movie.genres.length > 0 ?*/}
//                                     {/*    <div className="card text-white bg-success m-2 px-2 text-center flex-grow-1">*/}
//                                     {/*        <div className="card-header">ژانرها</div>*/}
//                                     {/*        <div className="card-body">*/}
//                                     {/*            <h5 className="card-title">*/}
//                                     {/*                /!*{*!/*/}
//                                     {/*                /!*    movie.genres.map((genre, index) => {*!/*/}
//                                     {/*                /!*        <span key={index}>genre.name<span>- </span></span>*!/*/}
//                                     {/*                /!*    })*!/*/}
//                                     {/*                /!*}*!/*/}
//                                     {/*            </h5>*/}
//                                     {/*        </div>*/}
//                                     {/*    </div>*/}
//                                     {/*        :*/}
//                                     {/*        <h1>aaaaaaaaaaaaaaaaaa</h1>*/}
//                                     {/*}*/}
//
//
//                                     <div className="card text-white bg-info m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">رده سنی</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title"> movie.adult ? 'بزرگسالان' : 'خانوادگی' </h5>
//                                         </div>
//                                     </div>
//                                     <div
//                                         className="card text-white bg-color-primary-3 m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">کمپانی سازنده</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title">
//                                 <span>
//                                 production_companie.name
//                                 <span>- </span>
//                             </span>
//                                             </h5>
//                                         </div>
//                                     </div>
//                                     <div className="card text-white bg-primary m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">تاریخ انتشار</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title"> movie.release_date </h5>
//                                         </div>
//                                     </div>
//                                     <div
//                                         className="card text-white bg-color-primary-4 m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">کشور سازنده</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title"> movie.production_countries[0].name </h5>
//                                         </div>
//                                     </div>
//                                     <div className="card text-white bg-black m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">تعداد رای</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title"> movie.vote_count رای</h5>
//                                         </div>
//                                     </div>
//                                     <div className="card text-white bg-danger m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">میانگین امتیاز</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title"> movie.vote_average از 10</h5>
//                                         </div>
//                                     </div>
//                                     <div className="card text-white bg-dark m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">تاریخ اکران</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title"> movie.release_date </h5>
//                                         </div>
//                                     </div>
//                                     <div
//                                         className="card text-white bg-color-primary-2 m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">شناسه IMBD</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title">
//                                                 <Link to="#" target="_blank" className="text-white">
//                                                     movie.imdb_id
//                                                 </Link>
//                                                 <p className="text-white m-0"> movie.imdb_id </p>
//                                             </h5>
//                                         </div>
//                                     </div>
//                                     <div className="card text-white bg-warning m-2 px-2 text-center flex-grow-1">
//                                         <div className="card-header">بودجه تولید</div>
//                                         <div className="card-body">
//                                             <h5 className="card-title">$ movie.budget </h5>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         :
//                         <Spinner/>
//                 }
//
//
//                 <div className="card">
//                     <div className="card-header bg-success">
//                         <h4 className="text-center text-white my-2">تصاویر movie.title</h4>
//                     </div>
//                     <div className="card-body">
//                         <div className="row border rounded-1 mb-2 p-2 align-items-center">
//                             <div className="col-md-6 my-1">
//                                 <img src={NoImage} className="img-fluid w-100"
//                                      alt="movie.original_title" title="movie.original_title"/>
//                                 <img src={NoCover} className="img-fluid w-100" alt="movie.original_title"
//                                      title="movie.original_title"/>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="card">
//                     <div className="card-header bg-success">
//                         <h4 className="text-center text-white my-2">پوستر movie.title</h4>
//                     </div>
//                     <div className="card-body">
//                         <div className="row border rounded-1 mb-2 p-2 align-items-center">
//                             <div className="col-md-3 col-lg-2 my-1">
//                                 <img src={NoImage} className="img-fluid w-100"
//                                      alt="movie.original_title" title="movie.original_title"/>
//                                 <img src={NoCover} className="img-fluid w-100" alt="movie.original_title"
//                                      title="movie.original_title"/>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         )
//     }
// }