import {apiKey, lang, imageSizeMovie, translatePersian} from "../../mixins";
import NoCover from "../../assets/no_cover.jpg";
import Spinner from "../layout/Spinner";

export default function BioTv(props) {
    const {tv} = props
    return (
        <>
            {
                Object.keys(tv).length > 0 ?
                    <div className="card mb-3">
                        <div className="card-header p-0 m-0">
                            {
                                tv.backdrop_path && !tv.adult ?
                                    <img src={imageSizeMovie('original') + tv.backdrop_path}
                                         className="img-fluid w-100" alt={tv.original_title}
                                         title={tv.original_title}/>
                                    :
                                    <img src={NoCover} className="img-fluid w-100" alt={tv.original_title}
                                         title={tv.original_title}/>
                            }
                        </div>
                        <div className="card-body">
                            <h1 className="text-center">{tv.name}</h1>
                            <p className="text-center">وضعیت:<span
                                className="ms-1 badge bg-primary">{translatePersian(tv.status)}</span></p>
                            <p className="text-center">{tv.overview}</p>
                            <div className="d-flex flex-wrap align-content-center justify-content-between">
                                {
                                    tv.genres && tv.genres.length > 0 ?
                                        <div className="card text-white bg-success m-2 px-2 text-center flex-grow-1">
                                            <div className="card-header">ژانرها</div>
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    {
                                                        tv.genres ? tv.genres.map((genre, index) =>
                                                                <span key={index}>{translatePersian(genre.name)}
                                                                    {
                                                                        index !== Object.keys(tv.genres).length - 1 &&
                                                                        <span>- </span>
                                                                    }
                                                        </span>
                                                        ) : []
                                                    }
                                                </h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    tv.created_by && tv.created_by.length > 0 ?
                                        <div className="card text-white bg-info m-2 px-2 text-center flex-grow-1">
                                            <div className="card-header">خلق شده توسط</div>
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    {
                                                        tv.created_by ? tv.created_by.map((person, index) =>
                                                                <span key={index}>{person.name}
                                                                    {
                                                                        index !== Object.keys(tv.created_by).length - 1 &&
                                                                        <span>- </span>
                                                                    }
                                                        </span>
                                                        ) : []
                                                    }
                                                </h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                <div className="card text-white bg-color-primary-3 m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">نحوه اکران</div>
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {tv.number_of_seasons} فصل - {tv.number_of_episodes} قسمت
                                        </h5>
                                    </div>
                                </div>

                                {
                                    tv.production_companies && tv.production_companies.length > 0 ?
                                        <div className="card text-white bg-primary m-2 px-2 text-center flex-grow-1">
                                            <div className="card-header">کمپانی سازنده</div>
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    {
                                                        tv.production_companies ? tv.production_companies.map((production_companie, index) =>
                                                                <span key={index}>{production_companie.name}
                                                                    {
                                                                        index !== Object.keys(tv.production_companies).length - 1 &&
                                                                        <span>- </span>
                                                                    }
                                                        </span>
                                                        ) : []
                                                    }
                                                </h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                {
                                    tv.production_countries && tv.production_countries.length > 0 ?
                                        <div
                                            className="card text-white bg-color-primary-4 m-2 px-2 text-center flex-grow-1">
                                            <div className="card-header">کشور سازنده</div>
                                            <div className="card-body">
                                                <h5 className="card-title"> {tv.production_countries[0].name} </h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                <div className="card text-white bg-black m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">تعداد رای</div>
                                    <div className="card-body">
                                        <h5 className="card-title">{tv.vote_count} رای</h5>
                                    </div>
                                </div>
                                <div className="card text-white bg-danger m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">میانگین امتیاز</div>
                                    <div className="card-body">
                                        <h5 className="card-title">{tv.vote_average} از 10</h5>
                                    </div>
                                </div>
                                <div className="card text-white bg-dark m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">تاریخ اکران</div>
                                    <div className="card-body">
                                        <h5 className="card-title">{tv.first_air_date}</h5>
                                    </div>
                                </div>
                                <div className="card text-white bg-warning m-2 px-2 text-center flex-grow-1">
                                    <div className="card-header">شبکه پخش</div>
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {
                                                tv.homepage ?
                                                    <a href={tv.homepage} target="_blank" className="text-white">
                                                        {tv.networks[0].name}
                                                    </a>
                                                    :
                                                    <p className="text-white">{tv.imdb_id}</p>
                                            }
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner/>
            }
        </>
    )
}