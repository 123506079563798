export const lang = "en-US";
export const apiKey = "4fb16533dea4ee2177cebced4bfc004d";

export function imageSizeMovie(size) {
    return `https://image.tmdb.org/t/p/${size}/`
}

export function translatePersian(value) {
    switch (value) {
        case 'Action':
            return 'اکشن'
        case 'Adventure':
            return 'ماجراجویی '
        case 'Animation':
            return 'انیمیشن'
        case 'Comedy':
            return 'کمدی'
        case 'Crime':
            return 'جنایی'
        case 'Documentary':
            return 'مستند'
        case 'Drama':
            return 'درام'
        case 'Family':
            return 'خانوادگی'
        case 'Fantasy':
            return 'فانتزی'
        case 'History':
            return 'تاریخی'
        case 'Horror':
            return 'ترسناک '
        case 'Music':
            return 'موزیک'
        case 'Mystery':
            return 'معمایی'
        case 'Romance':
            return 'رمانتیک'
        case 'Science Fiction':
            return 'علمی تخیلی'
        case 'TV Movie':
            return 'تلوزیون'
        case 'Thriller':
            return 'هیجان انگیز'
        case 'War':
            return 'جنگی'
        case 'Western':
            return 'وسترن'
        case 'Action & Adventure':
            return 'اکشن و ماجراجویی'
        case 'Kids':
            return 'کودکان'
        case 'News':
            return 'اخبار'
        case 'Reality':
            return 'واقعیت'
        case 'Sci-Fi & Fantasy':
            return 'علمی تخیلی و فانتزی'
        case 'Soap':
            return 'گروهی'
        case 'Talk':
            return 'گفتگویی'
        case 'War & Politics':
            return 'جنگ و سیاست'
        case 'Returning Series':
            return 'در انتظار پخش فصل جدید'
        case 'Ended':
            return 'پایان یافت'
        case 'Planned':
            return 'برنامه ریزی شده'
        case 'In Production':
            return 'در حال تولید'
        case 'Canceled':
            return 'کنسل شده'
        case 'Pilot':
            return 'آزمایشی'
        default:
            console.log(`Sorry, we are out of ${value}.`);
    }
}
