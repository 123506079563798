import Spinner from '../components/layout/Spinner'
import TheSidebar from "../components/layout/TheSidebar";
import CardViewMovie from "../components/cartView/CardViewMovie";
import CardViewTv from "../components/cartView/CardViewTv";
import {Component} from 'react';
import {apiKey, lang, imageSizeMovie} from "../mixins";
import apiAxios from "../Api";

export default class Home extends Component {
    constructor() {
        super();
        this.state = {
            moviePopular: [],
            tvPopular: [],
            statusTvPopular: false,
            movieTopRating: [],
            statusMovieTopRating: false,
        }
    }

    componentDidMount() {
        this.setArticle()
        this.getMovieGenres()
    }

    setArticle() {
        const data = JSON.stringify([{
            "title": "اولین مقاله من",
            "slug": "title1",
            "description": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
            "content": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. "
        }, {
            "title": "دومین مقاله من",
            "slug": "title2",
            "description": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
            "content": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. "
        }, {
            "title": "سومین مقاله من",
            "slug": "title3",
            "description": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
            "content": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. "
        }, {
            "title": "چهارمین مقاله من",
            "slug": "title4",
            "description": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
            "content": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. "
        }, {
            "title": "پنجمین مقاله من",
            "slug": "title5",
            "description": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
            "content": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. "
        }, {
            "title": "ششمین مقاله من",
            "slug": "title6",
            "description": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
            "content": " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. "
        }])
        localStorage.setItem("articles", data)
    }

    async getMovieGenres() {
        let page = 1
        let query = `/movie/popular?api_key=${apiKey}&language=${lang}&page=${page}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data.results
            this.setState({moviePopular: response});
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData Home queryMovieGenres');
        }
    }

    async getTvPopular() {
        if (this.state.statusTvPopular === false) {
            let page = 1
            let query = `/tv/popular?api_key=${apiKey}&language=${lang}&page=${page}`
            try {
                const request = await apiAxios.get(query)
                const response = request.data.results
                this.setState({tvPopular: response, statusTvPopular: true});
            } catch (error) {
                console.log(error)
                console.log('error in catch fetchData Home getTvPopular');
            }
        }
    }

    async getMovieTopRating() {
        if (this.state.statusMovieTopRating === false) {
            let page = 1
            let query = `/movie/top_rated?api_key=${apiKey}&language=${lang}&page=${page}`
            try {
                const request = await apiAxios.get(query)
                const response = request.data.results
                this.setState({movieTopRating: response, statusMovieTopRating: true});
            } catch (error) {
                console.log(error)
                console.log('error in catch fetchData Home getTvPopular');
            }
        }
    }

    render() {
        return (
            <>
                <TheSidebar/>
                <section id="PageHome" className="col-9 wrapper-text">
                    <div className="card mb-3">
                        <div className="card-header bg-color-primary-2 text-white text-center">پیام مدیریت</div>
                        <div className="card-body">
                            <h1>به وب سایت دانلود فیلم من خوش آمدید.</h1>
                            <p>در این وب سایت شما می توانید جدید ترین فیلم های روز جهان را مشاهده نمایید. در این پروژه
                                که به صورت آزمایشی است سعی شده است که مهارت های برنامه نویسی <strong
                                    className="text-danger">React</strong> من مورد چالش قرار
                                بگیرد.</p>
                            <div className="alert alert-warning" role="alert">لطفا فیلترشکن خود را فعال کنید چون برای
                                برقراری ارتباط با API، فعال بودن فیلترشکن ضروری می باشد.
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-moviepopular-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-moviepopular" type="button" role="tab"
                                            aria-controls="nav-moviepopular" aria-selected="true">فیلم های محبوب
                                    </button>
                                    <button onClick={() => {
                                        this.getTvPopular()
                                    }} className="nav-link" id="nav-tvpopular-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-tvpopular" type="button" role="tab"
                                            aria-controls="nav-tvpopular" aria-selected="false">سریال های محبوب
                                    </button>
                                    <button onClick={() => {
                                        this.getMovieTopRating()
                                    }} className="nav-link" id="nav-toprating-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-toprating" type="button" role="tab"
                                            aria-controls="nav-toprating" aria-selected="false">بیشترین امتیاز
                                    </button>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-moviepopular" role="tabpanel"
                                     aria-labelledby="nav-moviepopular-tab">
                                    {
                                        this.state.moviePopular.length > 0 ?
                                            <div className="row">
                                                {
                                                    this.state.moviePopular.map((item, index) =>
                                                        <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                                                            <CardViewMovie movie={item} size={imageSizeMovie('w342')}/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <Spinner/>
                                    }
                                </div>
                                <div className="tab-pane fade" id="nav-tvpopular" role="tabpanel"
                                     aria-labelledby="nav-tvpopular-tab">
                                    {
                                        this.state.tvPopular.length > 0 ?
                                            <div className="row">
                                                {
                                                    this.state.tvPopular.map((item, index) =>
                                                        <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                                                            <CardViewTv movie={item} size={imageSizeMovie('w342')}/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <Spinner/>
                                    }
                                </div>
                                <div className="tab-pane fade" id="nav-toprating" role="tabpanel"
                                     aria-labelledby="nav-toprating-tab">
                                    {
                                        this.state.movieTopRating.length > 0 ?
                                            <div className="row">
                                                {
                                                    this.state.movieTopRating.map((item, index) =>
                                                        <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                                                            <CardViewMovie movie={item} size={imageSizeMovie('w342')}/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <Spinner/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}