import {Component} from 'react';
import spinner from "../../assets/spinner.svg";

export default class Spinner extends Component {
    render() {
        return (

            <div className="d-block m-3 text-center">
                <img src={spinner} alt="isLoading" width="100" height="100"/>
            </div>

        )
    }
}