import spinner from '../../assets/spinner.svg'
import {Component} from 'react';
import apiAxios from "../../Api";
import {lang, apiKey, translatePersian} from '../../mixins'
import {Link} from "react-router-dom";

export default class TheSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            movieGenres: [],
            tvGenres: [],
        }
    }

    async componentDidMount() {
        let queryMovieGenres = `genre/movie/list?api_key=${apiKey}&language=${lang}`
        try {
            const request = await apiAxios.get(queryMovieGenres)
            const response = request.data.genres
            this.setState({movieGenres: response});
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData TheSidebar');
        }
        let queryTvGenres = `genre/tv/list?api_key=${apiKey}&language=${lang}`
        try {
            const request = await apiAxios.get(queryTvGenres)
            const response = request.data.genres
            this.setState({tvGenres: response});
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData TheSidebar');
        }
    }

    render() {
        return (
            <section id="Sidebar" className="col-3">
                <div className="card mb-2">
                    <div className="card-header bg-color-primary-1 text-white text-center">ژانر فیلم ها</div>
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            {
                                this.state.movieGenres
                                    .map(movieGenre =>
                                        <li className="list-group-item p-0" key={movieGenre.id}>
                                            <Link to={'/genre-movie/' + movieGenre.id}
                                                  className="d-flex justify-content-between align-content-center p-2">
                                                <strong>{translatePersian(movieGenre.name)}</strong>
                                                <span>{movieGenre.name}</span>
                                            </Link>
                                        </li>
                                    )
                            }
                        </ul>
                        {
                            this.state.movieGenres.length <= 0 &&
                            <div className="d-block m-3 text-center">
                                <img src={spinner} alt="isLoading" width="100" height="100"/>
                            </div>
                        }
                    </div>
                </div>
                <div className="card">
                    <div className="card-header bg-color-primary-1 text-white text-center">شبکه تلوزیونی</div>
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            {
                                this.state.tvGenres
                                    .map(movieGenre =>
                                        <li className="list-group-item p-0" key={movieGenre.id}>
                                            <Link to={'/genre-tv/' + movieGenre.id}
                                                  className="d-flex justify-content-between align-content-center p-2">
                                                <strong>{translatePersian(movieGenre.name)}</strong>
                                                <span>{movieGenre.name}</span>
                                            </Link>
                                        </li>
                                    )
                            }
                        </ul>
                        {
                            this.state.tvGenres.length <= 0 &&
                            <div className="d-block m-3 text-center">
                                <img src={spinner} alt="isLoading" width="100" height="100"/>
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}