// import {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import {useState, useEffect , useContext} from "react";
import {Link} from "react-router-dom";
import authContext from "../context/auth";
export default function Login() {
    let navigate = useNavigate();
    let authContextValue = useContext(authContext);
    // const [authenticated, setAuthenticated] = useState(isAuthentication);
    // const [token, setToken] = useState(isToken);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState({
        usernameE: false,
        passwordE: false,
        usernameEM: "",
        passwordEM: "",
        access: false,
    });
    const handlerUsernameChange = event => {
        setUserName(event.target.value)
    }
    const handlerPasswordChange = event => {
        setPassword(event.target.value)
    }
    useEffect(() => {
        if (status.access) {
            console.log(userName + '   ' + password);
            localStorage.setItem('token', userName + "-" + password)
            authContextValue.login()
            navigate("/profile");
        }
    }, [status.access]);
    const doLogin = event => {
        event.preventDefault();
        setStatus(previousState => {
            return {...previousState, access: true}
        });
        if (userName.length < 5) {
            setStatus(previousState => {
                return {...previousState, usernameE: true, access: false}
            });
            if (userName.length === 0) {
                setStatus(previousState => {
                    return {...previousState, usernameEM: "نام کاربری ضروری است"}
                });
            } else {
                setStatus(previousState => {
                    return {...previousState, usernameEM: "نام کاربری باید بیشتر از 5 کاراکتر باشد."}
                });
            }
        } else {
            setStatus(previousState => {
                return {...previousState, usernameE: false, usernameEM: ""}
            });
        }
        if (password.length < 6) {
            setStatus(previousState => {
                return {...previousState, passwordE: true, access: false}
            });
            if (password.length === 0) {
                setStatus(previousState => {
                    return {...previousState, passwordEM: "کلمه عبور ضروری است"}
                });
            } else {
                setStatus(previousState => {
                    return {...previousState, passwordEM: "کلمه عبور باید بیشتر از 6 کاراکتر باشد."}
                });
            }
        } else {
            setStatus(previousState => {
                return {...previousState, passwordE: false, passwordEM: ""}
            });
        }
    }


    return (
        <div id="PageLogin">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mx-auto">
                    <div className="card shadow-lg">
                        <div className="card-header bg-color-primary-4 text-white py-1">
                            <span className="d-inline-flex align-middle mdi mdi-account-check mdi-24px me-1"/>ورود
                            به حساب کاربری
                        </div>
                        <div className="card-body">
                            <form onSubmit={doLogin}>
                                <div className="mb-3">
                                    <label htmlFor="usernameInput" className="form-label">نام کاربری</label>
                                    <input type="text" className="form-control" value={userName}
                                           onChange={handlerUsernameChange} id="usernameInput"/>
                                    {/*onChange={(event) => setUserName(event.target.value)} id="usernameInput"/>*/}
                                    {
                                        status.usernameE === true ?
                                            <div className="d-block invalid-feedback">
                                                {status.usernameEM}
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="passwordInput" className="form-label">کلمه عبور</label>
                                    <input type="password" className="form-control" value={password}
                                           onChange={handlerPasswordChange} id="passwordInput"/>
                                    {
                                        status.passwordE === true ?
                                            <div className="d-block invalid-feedback">
                                                {status.passwordEM}
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                                <button type="submit" className="btn btn-secondary w-100">ورود به سیستم</button>
                            </form>
                        </div>
                        <div className="card-footer text-muted text-center">
                            <Link to="/register">یک حساب کاربری جدید ایجاد کنید.</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// export default class Login extends Component {
//     state = {
//         userName: "",
//         password: "",
//         usernameE: false,
//         passwordE: false,
//         usernameEM: "",
//         passwordEM: "",
//         access: false,
//     }
//
//     handlerUsernameChange(e) {
//         const name = e.target.name;
//         const value = e.target.value;
//         this.setState({[name]: value});
//     }
//
//     doLogin(e) {
//         e.preventDefault();
//         this.setState({access: true});
//         if (this.state.userName.length < 5) {
//             this.setState({access: false});
//             this.setState({usernameE: true});
//             if (this.state.userName.length === 0) {
//                 this.setState({usernameEM: "نام کاربری ضروری است"});
//             } else {
//                 this.setState({usernameEM: "نام کاربری باید بیشتر از 5 کاراکتر باشد."});
//             }
//         } else {
//             this.setState({usernameE: false});
//             this.setState({usernameEM: ""});
//         }
//         if (this.state.password.length < 6) {
//             this.setState({access: false});
//             this.setState({passwordE: true});
//             if (this.state.password.length === 0) {
//                 this.setState({passwordEM: "کلمه عبور ضروری است"});
//             } else {
//                 this.setState({passwordEM: "کلمه عبور باید بیشتر از 6 کاراکتر باشد."});
//             }
//         } else {
//             this.setState({passwordE: false});
//             this.setState({passwordEM: ""});
//         }
//         if (this.state.access) {
//             console.log(this.state.userName + this.state.password);
//         }
//         console.log(this.state)
//     }
//
//     render() {
//         return (
//             <div id="PageLogin">
//                 <div className="row">
//                     <div className="col-12 col-md-6 col-lg-4 mx-auto">
//                         <div className="card shadow-lg">
//                             <div className="card-header bg-color-primary-4 text-white py-1">
//                                 <span className="d-inline-flex align-middle mdi mdi-account-check mdi-24px me-1"/>ورود
//                                 به حساب کاربری
//                             </div>
//                             <div className="card-body">
//                                 <form onSubmit={(e) => this.doLogin(e)}>
//                                     <div className="mb-3">
//                                         <label htmlFor="usernameInput" className="form-label">نام کاربری</label>
//                                         <input type="text" className="form-control" name="userName"
//                                                value={this.state.userName}
//                                                onChange={(e) => this.handlerUsernameChange(e)} id="usernameInput"/>
//                                         {
//                                             this.state.usernameE === true ?
//                                                 <div className="invalid-feedback">
//                                                     {this.state.usernameEM} aaaaaaaaaaaa
//                                                 </div>
//                                                 :
//                                                 ""
//                                         }
//                                     </div>
//                                     <div className="mb-3">
//                                         <label htmlFor="passwordInput" className="form-label">کلمه عبور</label>
//                                         <input type="password" className="form-control" name="password"
//                                                value={this.state.password}
//                                                onChange={(e) => this.handlerUsernameChange(e)} id="passwordInput"/>
//                                         {
//                                             this.state.usernameE === true ?
//                                                 <div className="invalid-feedback">
//                                                     {this.state.passwordEM} nnnnnnnnnnnnnn
//                                                 </div>
//                                                 :
//                                                 ""
//                                         }
//                                     </div>
//                                     <button type="submit" className="btn btn-secondary w-100">ورود به سیستم</button>
//                                 </form>
//                             </div>
//                             <div className="card-footer text-muted text-center">
//                                 <Link to="/register">یک حساب کاربری جدید ایجاد کنید.</Link>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }