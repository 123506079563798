import logo from '../../assets/logo.png'
import {Link} from "react-router-dom";
import {useContext} from "react";
import authContext from "../../context/auth";

export default function TheHeader() {
    let authContextValue = useContext(authContext);
    const toggleCalculator = () => {
        console.log("toggleCalculator")
    }
    return (
        <div id="TheHeader">
            <nav className="navbar navbar-dark bg-color-primary-2 navbar-expand-lg">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="My App Kiani" height="50"/>
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/">صفحه اصلی</Link>
                            </li>
                            {
                                authContextValue.isAuthentication === true &&
                                <li className="nav-item">
                                    <Link className="nav-link" to="/profile">پروفایل</Link>
                                </li>
                            }
                            <li className="nav-item">
                                <Link className="nav-link" to="/article">مقاله ها</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/search">جستجو پیشرفته</Link>
                            </li>
                            {
                                authContextValue.isAuthentication === true &&
                                <li className="nav-item">
                                    <Link className="nav-link" to="/add-article">نوشتن مقاله</Link>
                                </li>
                            }
                            <li className="nav-item">
                                <Link className="nav-link" to="#" onClick={toggleCalculator}>ماشین حساب</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav">
                            {
                                authContextValue.isAuthentication === false &&
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/register">ثبت نام</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login">ورود</Link>
                                    </li>
                                </>

                            }
                            {
                                authContextValue.isAuthentication === true &&
                                <li className="nav-item">
                                    <Link className="nav-link" to="/"
                                          onClick={() => authContextValue.logout()}>خروج</Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}