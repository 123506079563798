import {useState} from "react";
import {Link} from "react-router-dom";

export default function Article() {
    let localStorageArticles = localStorage.getItem("articles");
    let parseArticles = JSON.parse(localStorageArticles);
    const [articles, setArticles] = useState(parseArticles);

    return (
        <div id="Article">
            <div className="wrapper-text">
                <h1>جدیدترین مقالات من</h1>
                <p>
                    در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند
                    هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد
                    بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.
                </p>
                <div className="alert alert-info" role="alert">مقالات روی Local Storage مرورگر شما
                    بارگزاری شده اند و تغییرات شما موقت می باشد.
                </div>
            </div>
            <div className="row">
                {
                    articles && articles.map(item =>
                        <div className="col-md-6 col-lg-4" key={item.slug}>
                            <div className="card mb-3">
                                <div className="card-header bg-color-primary-4 text-white">{item.title}</div>
                                <div className="card-body">
                                    <p className="card-text">{item.description}</p>
                                    <div className="text-end">
                                        <Link to={"/article/" + item.slug} className="btn btn-secondary btn-sm">
                                            <span className="d-inline-flex align-middle mdi mdi-eye me-1"/>
                                            بیشتر بخوانید
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}