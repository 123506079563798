import EpisodeTV from "../components/section/EpisodeTV";
import SeasonTv from "../components/section/SeasonTv";
import BioTv from "../components/section/BioTv"
import {useParams} from "react-router-dom";
import {apiKey, lang} from "../mixins";
import apiAxios from "../Api";
import {useState, useEffect} from "react";
import EpisodeTvDetail from "../components/section/EpisodeTvDetail";

export default function PageSingleEpisode() {
    const [tv, setTv] = useState([]);
    const [season, setSeason] = useState([]);
    const [episode, setEpisode] = useState([]);
    const [episodeCrew, setEpisodeCrew] = useState([]);
    const [episodeGuestStars, setEpisodeGuestStars] = useState([]);
    const tv_id = useParams();

    useEffect(async () => {

        let query = `/tv/${tv_id.id}?api_key=${apiKey}&language=${lang}&language=${lang}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data
            setTv(response);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageSingleMovie getMovie');
        }
        window.scrollTo(0, 0)
    }, [tv_id])

    useEffect(async () => {
        let query = `/tv/${tv_id.id}/season/${tv_id.season}?api_key=${apiKey}&language=${lang}&language=${lang}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data
            setSeason(response);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageSingleMovie getMovie');
        }
        window.scrollTo(0, 0)
    }, [tv_id])

    useEffect(async () => {
        let query = `/tv/${tv_id.id}/season/${tv_id.season}/episode/${tv_id.episode}?api_key=${apiKey}&language=${lang}&language=${lang}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data
            setEpisode(response);
            setEpisodeCrew(response.crew);
            setEpisodeGuestStars(response.guest_stars);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageSingleMovie getMovie');
        }
        window.scrollTo(0, 0)
    }, [tv_id])

    return (
        <section id="PageSingleSeason" className="col-12 wrapper-text">
            <EpisodeTvDetail tv={tv} season={season} episode={episode} episodeCrew={episodeCrew}
                             episodeGuestStars={episodeGuestStars}/>
            <EpisodeTV tv={tv} season={season} tvId={tv_id.id}/>
            <SeasonTv tv={tv} tvId={tv_id.id}/>
            <BioTv tv={tv}/>
        </section>

    )
}