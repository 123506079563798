import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import {apiKey, lang} from "../mixins";
import apiAxios from "../Api";
import BioPerson from "../components/section/BioPerson";
import ImagePerson from "../components/section/ImagePerson";

export default function PageSinglePerson(props) {
    const [person, setPerson] = useState([]);
    const [personImage, setPersonImage] = useState([]);
    const person_id = useParams();

    useEffect( () => {
        getPerson(person_id.id)
        getPersonImage(person_id.id)
    }, [])

    const getPerson =  async (id) =>{
        let query = `/person/${id}?api_key=${apiKey}&language=${lang}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data
            setPerson(response);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageGenreListMovie getGenreListMovie');
        }
    }
    const getPersonImage =  async (id) =>{
        let query = `/person/${id}/images?api_key=${apiKey}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data.profiles
            setPersonImage(response);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageGenreListMovie getGenreListMovie');
        }
    }

    return (
    <section id="PageSingleSeason" className="col-12 wrapper-text">
            <BioPerson person={person} />
            <ImagePerson person={person} personImage={personImage} />
    </section>
    )
}