import {useNavigate} from 'react-router-dom';
import {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import authContext from "../context/auth";

export default function Register() {
    let authContextValue = useContext(authContext);
    let navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [status, setStatus] = useState({
        usernameE: false,
        passwordE: false,
        password2E: false,
        usernameEM: "",
        passwordEM: "",
        password2EM: "",
        access: false,
    });
    useEffect(() => {
        if (status.access) {
            console.log(userName + '   ' + password);
            localStorage.setItem('token', userName + "-" + password)
            authContextValue.login()
            navigate("/profile");
        }
    }, [status.access]);
    const doRegister = event => {
        event.preventDefault();
        console.log(status)

        setStatus(previousState => {
            return {...previousState, access: true}
        });
        if (userName.length < 5) {
            setStatus(previousState => {
                return {...previousState, usernameE: true, access: false}
            });
            if (userName.length === 0) {
                setStatus(previousState => {
                    return {...previousState, usernameEM: "نام کاربری ضروری است"}
                });
            } else {
                setStatus(previousState => {
                    return {...previousState, usernameEM: "نام کاربری باید بیشتر از 5 کاراکتر باشد."}
                });
            }
        } else {
            setStatus(previousState => {
                return {...previousState, usernameE: false, usernameEM: ""}
            });
        }
        if (password.length < 6) {
            setStatus(previousState => {
                return {...previousState, passwordE: true, access: false}
            });
            if (password.length === 0) {
                console.log("کلمه عبور ضروری است")
                setStatus(previousState => {
                    return {...previousState, passwordEM: "کلمه عبور ضروری است"}
                });
            } else {
                setStatus(previousState => {
                    return {...previousState, passwordEM: "کلمه عبور باید بیشتر از 6 کاراکتر باشد."}
                });
            }
        } else {
            setStatus(previousState => {
                return {...previousState, passwordE: false, passwordEM: ""}
            });
        }
        if (password2.length < 6) {
            setStatus(previousState => {
                return {...previousState, password2E: true, access: false}
            });
            if (password2.length === 0) {
                setStatus(previousState => {
                    return {...previousState, password2EM: "تکرار کلمه عبور ضروری است"}
                });
            } else {
                setStatus(previousState => {
                    return {...previousState, password2EM: "تکرار کلمه عبور باید بیشتر از 6 کاراکتر باشد."}
                });
            }
        } else {
            setStatus(previousState => {
                return {...previousState, password2E: false, password2EM: ""}
            });
        }
        if (password !== password2) {
            setStatus(previousState => {
                return {
                    ...previousState,
                    access: false,
                    passwordE: true,
                    password2E: true,
                    passwordEM: "کلمه عبورها همسان نیستند."
                }
            });
        } else {
            // if (!status.passwordEM && !status.password2EM) {
            //     setStatus(previousState => {
            //         return {...previousState, passwordEM: ""}
            //     });
            // }
        }
    }
    return (
        <div id="PageRegister">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mx-auto">
                    <div className="card shadow-lg">
                        <div className="card-header bg-color-primary-3 text-white py-1"><span
                            className="d-inline-flex align-middle mdi mdi-account mdi-24px me-1"/>ایجاد حساب کاربری
                        </div>
                        <div className="card-body">
                            <form onSubmit={doRegister}>
                                <div className="mb-3">
                                    <label htmlFor="usernameInput" className="form-label">نام کاربری</label>
                                    <input type="text" className="form-control" id="usernameInput" value={userName}
                                           onChange={(event) => setUserName(event.target.value)}/>
                                    {
                                        status.usernameE === true ?
                                            <div className="d-block invalid-feedback">
                                                {status.usernameEM}
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="passwordInput" className="form-label">کلمه عبور</label>
                                    <input type="password" className="form-control" id="passwordInput" value={password}
                                           onChange={(event) => setPassword(event.target.value)}/>
                                    {
                                        status.passwordE === true ?
                                            <div className="d-block invalid-feedback">
                                                {status.passwordEM}
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password2Input" className="form-label">تکرار کلمه عبور</label>
                                    <input type="password" className="form-control" id="password2Input"
                                           value={password2} onChange={(event) => setPassword2(event.target.value)}/>
                                    {
                                        status.password2E === true ?
                                            <div className="d-block invalid-feedback">
                                                {status.password2EM}
                                            </div>
                                            :
                                            ""
                                    }
                                    <div className="invalid-feedback">
                                        password2EM
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-secondary w-100">ایجاد حساب کاربری</button>
                            </form>
                        </div>
                        <div className="card-footer text-muted text-center">
                            <Link to="/login">چنانچه حساب کاربری دارید وارد شوید.</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}