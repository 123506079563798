import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import "@mdi/font/css/materialdesignicons.min.css";
import './assets/scss/main.scss'
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
