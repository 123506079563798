import NoImage from '../../assets/no_image.jpg'
import {imageSizeMovie} from "../../mixins";
import {Link} from "react-router-dom";
import Spinner from "../layout/Spinner";

export default function EpisodeTvDetail(props) {
    const {tv, season, episode, episodeCrew, episodeGuestStars} = props
    const setGender = (value) => {
        switch (value) {
            case 0:
                return 'نامشخص'
            case 1:
                return 'خانم'
            case 2:
                return 'آقا'
        }
    }

    return (

        <div className="card mb-4">
            <div className="card-header bg-success">
                <h4 className="text-center text-white my-2">مشخصات
                    قسمت {episode.episode_number} فصل {episode.season_number} برای {tv.name}</h4>
            </div>
            <div className="card-body">
                <p className="text-center mb-0" dir="ltr"><strong>Title Episode: </strong>{episode.name}</p>
                <p className="text-center mb-0" dir="ltr"><strong>overview: </strong>{episode.overview}</p>
                <p className="text-center" dir="ltr"><strong>Vote Average: </strong>{episode.vote_average}/10
                    for {episode.vote_count} Vote</p>

                {
                    Object.keys(episodeCrew).length > 0 ?
                        <>
                            <h4 className="text-center p-2 m-0 bg-color-primary-2 text-white">مشخصات عوامل فیلم</h4>
                            <div className="table-responsive mb-3">
                                <table className="table table-sm table-striped table-hover align-middle text-center">
                                    <thead className="table-success">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">تصویر</th>
                                        <th scope="col">جنسیت</th>
                                        <th scope="col">نام و نام خانوادگی</th>
                                        <th scope="col">نقش</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        episodeCrew.map((itemCrew, index) =>
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>
                                                    {
                                                        itemCrew.profile_path ?
                                                            <img className="img-fluid"
                                                                 src={imageSizeMovie('w185') + itemCrew.profile_path}
                                                                 alt={itemCrew.original_name}
                                                                 title={itemCrew.original_name}
                                                                 width="80"/>
                                                            :
                                                            <img className="img-fluid" src={NoImage}
                                                                 alt={itemCrew.original_name}
                                                                 title={itemCrew.original_name} width="80"/>

                                                    }

                                                </td>
                                                <td>{setGender(itemCrew.gender)}</td>
                                                <td>{itemCrew.original_name}</td>
                                                <td>{itemCrew.job}</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>


                            </div>
                        </>
                        :
                        <Spinner/>
                }

                {
                    Object.keys(episodeGuestStars).length > 0 ?
                        <>
                            <h4 className="text-center p-2 m-0 bg-color-primary-2 text-white">مشخصات بازیگران این
                                قسمت</h4>
                            <div className="table-responsive">
                                <table className="table table-sm table-striped table-hover align-middle text-center">
                                    <thead className="table-success">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">تصویر</th>
                                        <th scope="col">نام و نام خانوادگی</th>
                                        <th scope="col">جنسیت</th>
                                        <th scope="col">در نقش</th>
                                        <th scope="col">پروفایل</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        episodeGuestStars.map((itemGuestStars, index) =>
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>
                                                    {
                                                        itemGuestStars.profile_path ?
                                                            <img className="img-fluid"
                                                                 src={imageSizeMovie('w185') + itemGuestStars.profile_path}
                                                                 alt={itemGuestStars.original_name}
                                                                 title={itemGuestStars.original_name}
                                                                 width="80"/>
                                                            :
                                                            <img className="img-fluid" src={NoImage}
                                                                 alt={itemGuestStars.original_name}
                                                                 title={itemGuestStars.original_name} width="80"/>

                                                    }
                                                </td>
                                                <td>{setGender(itemGuestStars.gender)}</td>
                                                <td>{itemGuestStars.original_name}</td>
                                                <td>{itemGuestStars.character}</td>
                                                <td>
                                                    <Link className="btn btn-success btn-sm text-white text-end"
                                                          to={'/person/' + itemGuestStars.id}>مشاهده پروفایل</Link>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </>
                        :
                        <Spinner/>
                }


            </div>
        </div>

    )
}