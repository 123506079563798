import {useEffect} from "react";
import {translatePersian} from "../../mixins";

export default function ThePagination(params) {
    const maxVisibleBtn = 5

    useEffect(() => {
        pages()
    }, [])


    const isInFirstPage = () => {
        return params.currentPage === 1
    }
    const isInLastPage = () => {
        return params.currentPage === params.totalPage
    }
    const startPage = () => {
        if (params.currentPage === 1) return 1
        if (params.currentPage === params.totalPage) return params.totalPage - 4
        if (params.currentPage === 2) return 1;
        return params.currentPage - 2

    }
    const endPage = () => {
        return Math.min(startPage() + maxVisibleBtn - 1, params.totalPage)
    }
    const pages = () => {
        let range = [];
        for (let i = startPage(); i <= endPage(); i++) {
            range.push({
                name: i,
                isActive: i === params.currentPage
            })
        }
        console.log(range)
        return range;
    }

    return (
        <div id="ThePagination">
            <nav aria-label="Pagination">
                <hr className="my-0"/>
                <ul className="pagination justify-content-center my-4">
                    <li className="page-item disabled" className={isInFirstPage ? 'page-item disabled' : 'page-item'}>
                        <button type="button" className="page-link">جدیدترین</button>
                    </li>

                    {
                        pages && pages.length > 0 ?

                            pages ? pages.map((page, index) =>
                                <li className={page.isActive ? 'page-item disabled' : 'page-item'} aria-current="page"
                                    key={page.name}>
                                    <button type="button"
                                            className={page.isActive ? 'page-link disabled' : 'page-link'}>
                                        {page.name}
                                    </button>
                                </li>
                            ) : []
                            :
                            ""
                    }


                    <li className="page-item disabled" className={isInLastPage ? 'page-item disabled' : 'page-item'}>
                        <button type="button" className="page-link">قدیمی ترین</button>
                    </li>
                </ul>
            </nav>
        </div>
    )
}