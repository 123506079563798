import Spinner from "../components/layout/Spinner";
import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {apiKey, lang, imageSizeMovie} from "../mixins";
import apiAxios from "../Api";
import CardViewTv from "../components/cartView/CardViewTv";

export default function PageGenreListTv() {

    const [genreListTv, setGenreListTv] = useState([]);
    const genre_id = useParams();


    useEffect( () => {
        getGenreListTv(1)
    }, [])

    const getGenreListTv =  async (page) =>{
        let query = `/discover/tv?api_key=${apiKey}&language=${lang}&sort_by=popularity.desc&page=${page}&with_genres=${genre_id.id}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data.results
            setGenreListTv(response);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageGenreListTv getGenreListTv');
        }
    }

    return (
        <>
            <section id="PageGenreListTv" className="col-12 wrapper-text">
                <div className="card p-3">
                    <div className="card-header bg-color-primary-2 text-white text-center">تلوزیون خانگی با ژانر</div>
                    <div className="card-body">
                        <div className="row">
                            {
                                genreListTv.length > 0 ?
                                    <div className="row">
                                        {
                                            genreListTv.map((item, index) =>
                                                <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                                                    <CardViewTv movie={item} size={imageSizeMovie('w500')}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                    :
                                    <Spinner/>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <div className="col-12">

            </div>
        </>
    )
}