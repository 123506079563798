import spinner from "../../assets/spinner.svg";
import CardViewTvEpisode from "../cartView/CardViewTvEpisode";
import {imageSizeMovie} from "../../mixins";
import Spinner from "../layout/Spinner";

export default function EpisodeTV(props) {
    const {tv, season, tvId} = props
    return (
        <>{
            Object.keys(tv).length > 0 && Object.keys(season).length > 0 ?
                <div className="card mb-4">
                    <div className="card-header bg-success">
                        <h3 className="text-center text-white my-2">قسمت های
                            فصل {season.season_number} برای {tv.name}</h3>
                        <p className="text-center text-white m-0">همه قسمت های این فصل نمایش شبکه تلوزیونی را در زیر
                            مشاهده
                            نمایید.</p>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {
                                season.overview ?
                                    <div className="col-12">
                                        <p className="text-center" dir="ltr">
                                            <strong>overview: </strong>{season.overview}
                                        </p>
                                    </div>
                                    :
                                    ""
                            }

                            {
                                season.episodes ? season.episodes.map((episode, index) =>

                                        <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                                            <CardViewTvEpisode episode={episode} tvID={tvId} size={imageSizeMovie('w342')}/>
                                        </div>
                                    )
                                    : []
                            }

                        </div>
                    </div>
                </div>
                :
                <Spinner/>
        }
        </>
    )
}