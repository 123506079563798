import {Link} from "react-router-dom";
import NoPoster from "../../assets/no_cover.jpg";

export default function CardViewTvEpisode(props) {
    const {episode, tvID, size} = props
    return (
        <div className="card-view my-2">
            <div className="card-header">
                <Link to={'/tv/' + tvID + '/' + episode.season_number + '/' + episode.episode_number}>
                    {
                        episode.still_path ?
                            <img className="img-fluid w-100" src={size + episode.still_path} title={episode.name} alt={episode.name}/>
                            :
                            <img className="img-fluid w-100" src={NoPoster} title={episode.name} alt={episode.name}/>
                    }
                </Link>
            </div>
            <div className="card-movie-content">
                <div className="card-movie-content-head">
                    <Link to={'/tv/' + tvID + '/' + episode.season_number + '/' + episode.episode_number}
                          className="d-block text-center">
                        <div>قسمت {episode.episode_number}</div>
                        <div>{episode.name}</div>
                    </Link>
                    <div className="ratings"><span>{episode.vote_average}</span>/10</div>
                </div>
            </div>
        </div>
    )
}