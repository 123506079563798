import NoImage from "../../assets/no_image.jpg";
import {imageSizeMovie} from "../../mixins";
import Spinner from "../layout/Spinner";

export default function BioPerson(props) {
    const {person} = props
    return (
        <>
            {
                Object.keys(person).length > 0 ?
                    <div className="card mb-4">
                        <div className="card-header bg-success">
                            <h4 className="text-center text-white my-2">{person.name}</h4>
                        </div>
                        <div className="card-body">
                            <div className="row border rounded-1 mb-2 p-2 align-items-center">
                                <div className="col-md-3 col-lg-2">

                                    {
                                        person.profile_path ?
                                            <img className="img-fluid rounded-1"
                                                 src={imageSizeMovie('w185') + person.profile_path}
                                                 title={person.name} alt={person.name} width={300}/>
                                            :
                                            <img className="img-fluid rounded-1" src={NoImage} title={person.name}
                                                 alt={person.name}
                                                 width={300}/>
                                    }

                                    <h1 className="my-2 text-center">{person.name}</h1>
                                </div>
                                <div className="col-md-9 col-lg-10">
                                    {
                                        person.birthday ?
                                            <p className="mb-1"><strong className="me-1">تاریخ تولد:</strong><span
                                                dir="ltr">{person.birthday}</span></p>
                                            :
                                            ""
                                    }
                                    {
                                        person.place_of_birth ?
                                            <p className="mb-1"><strong className="me-1">محل تولد:</strong><span
                                                dir="ltr">{person.place_of_birth}</span></p>
                                            :
                                            ""
                                    }

                                    {
                                        person.deathday ?
                                            <p className="mb-1"><strong className="me-1">تاریخ وفات:</strong><span
                                                dir="ltr">{person.deathday}</span></p>
                                            :
                                            ""
                                    }
                                    {
                                        person.biography ?
                                            <p className="mb-1"><strong className="me-1">بیوگرافی:</strong><span
                                                dir="ltr">{person.biography}</span></p>
                                            :
                                            ""
                                    }
                                    {
                                        person.known_for_department ?
                                            <p className="mb-1"><strong className="me-1">بازیگر بخش:</strong><span
                                                dir="ltr">{person.known_for_department}</span></p>
                                            :
                                            ""
                                    }
                                    {
                                        person.popularity ?
                                            <p className="mb-1"><strong className="me-1">امتیاز:</strong><span
                                                dir="ltr">{person.popularity}/10</span></p>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner/>
            }
        </>
    )
}