export default function AddArticle() {
    return (
        <div id="Add">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-color-primary-4 text-white">
                            ثبت مقاله جدید
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="mb-3">
                                    <label className="form-label">عنوان مقاله</label>
                                    <input type="text" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">توضیحات مقاله</label>
                                    <textarea className="form-control" rows="2"></textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">متن کامل مقاله</label>
                                    <textarea className="form-control" rows="8"></textarea>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-sm btn-secondary">
                                        <span className="d-inline-flex align-middle mdi mdi-content-save me-1"></span>
                                        ثبت مقاله جدید
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}