import image404 from '../assets/404.jpg'
export default function NoPage(){
    return(
        <div id="noPage"className="wrapper-text">
            <div className="d-block text-center">
                <img src={image404} className="img-fluid mb-3" alt="404" />
                <h1>صفحه مورد نظر یافت نشد</h1>
            </div>
        </div>
    )
}