import NoImage from "../../assets/no_image.jpg";
import {imageSizeMovie} from "../../mixins";
import Spinner from "../layout/Spinner";

export default function ImagePerson(props) {
    const {person, personImage} = props
    return (
        <>
            {
                Object.keys(personImage).length > 0 ?
                    <div className="card mb-4">
                        <div className="card-header bg-success">
                            <h4 className="text-center text-white my-2">تصاویر {person.name}</h4>
                        </div>
                        <div className="card-body">
                            <div className="row border rounded-1 mb-2 p-2 align-items-center">
                                {
                                    personImage ?
                                        personImage.map((photo, index) =>
                                            <div className="col-md-3 col-lg-2 my-1" key={index}>
                                                {
                                                    photo.file_path ?
                                                        <img className="img-fluid rounded-1"
                                                             src={imageSizeMovie('w185') + photo.file_path}
                                                             title={person.name}
                                                             alt={person.name} width="300"/>
                                                        :
                                                        <img className="img-fluid rounded-1" src={NoImage}
                                                             title={person.name}
                                                             alt={person.name} width="300"/>
                                                }
                                            </div>
                                        )
                                        :
                                        ""
                                }

                            </div>
                        </div>
                    </div>
                    :
                    <Spinner/>
            }
        </>
    )
}