import ThePagination from '../components/layout/ThePagination'
import CardViewMovie from "../components/cartView/CardViewMovie";
import Spinner from "../components/layout/Spinner";
import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {apiKey, lang, imageSizeMovie} from "../mixins";
import apiAxios from "../Api";

export default function PageGenreListMovie() {

    const [genreListMovie, setGenreListMovie] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState({
        current: 1,
        totalPage: 0
    });
    const genre_id = useParams();


    useEffect( () => {
        getGenreListMovie(page)
    }, [page])

    const getGenreListMovie =  async (page) =>{
        let query = `/discover/movie?api_key=${apiKey}&language=${lang}&sort_by=popularity.desc&page=${page}&with_genres=${genre_id.id}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data.results
            const totalPageRequest = request.data.total_results
            setGenreListMovie(response);
            setPages({current: page, totalPage: totalPageRequest})
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageGenreListMovie getGenreListMovie');
        }
    }

    return (
        <>
            <section id="PageGenreListMovie" className="col-12 wrapper-text">
                <div className="card p-3">
                    <div className="card-header bg-color-primary-2 text-white text-center">فیلم های ژانر</div>
                    <div className="card-body">
                        <div className="row">
                            {
                                genreListMovie.length > 0 ?
                                    <div className="row">
                                        {
                                            genreListMovie.map((movie, index) =>
                                                <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                                                    <CardViewMovie movie={movie} size={imageSizeMovie('w500')}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                    :
                                    <Spinner/>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <div className="col-12">
                <ThePagination
                    currentPage={pages.current}
                    totalPage={pages.totalPage} />
            </div>
        </>
    )
}