import {Link} from "react-router-dom";
import noImage from '../../assets/no_image.jpg'

export default function CardViewMovie(props) {
    const {movie, size} = props
    return (
        <div className="card-view my-2">
            <div className="card-header">
                <Link to={'/movie/' + movie.id}>
                    {
                        movie.poster_path.length ?
                            <img className="img-fluid w-100" src={size + movie.poster_path}
                                 title={movie.title} alt={movie.title}/>
                            :
                            <img className="img-fluid w-100" src={noImage} title={movie.title}
                                 alt={movie.title}/>
                    }
                </Link>
            </div>
            <div className="card-movie-content">
                <div className="card-movie-content-head">
                    <Link to={'/movie/' + movie.id} className="d-block text-center">{movie.title}</Link>
                    <div className="ratings"><span>{movie.vote_average}</span>/10</div>
                </div>
            </div>
        </div>
    )
}