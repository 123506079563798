import CardViewTvSeasons from "../cartView/CardViewTvSeasons";
import {imageSizeMovie, translatePersian} from "../../mixins";
import Spinner from "../layout/Spinner";

export default function SeasonTv(props) {
    const {tv, tvId} = props
    return (
        <>
            {
                Object.keys(tv).length > 0 ?
                    <div className="card mb-4">
                        <div className="card-header bg-color-primary-1">
                            <h2 className="text-center text-white my-2">فصل های {tv.name}</h2>
                            <p className="text-center text-white m-0">همه فصل های این نمایش شبکه تلوزیونی را در زیر
                                مشاهده
                                نمایید.</p>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {
                                    tv.seasons ? tv.seasons.map((season, index) =>

                                            <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                                                <CardViewTvSeasons season={season} tvID={tvId} size={imageSizeMovie('w342')}
                                                                   tvId={tvId}/>
                                            </div>
                                        )
                                        : []
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner/>
            }
        </>
    )
}