import {Link} from "react-router-dom";
import NOImage from "../../assets/no_image.jpg";

export default function CardViewTvSeasons(props) {
    const {season, tvID, size} = props
    return (
        <div className="card-view my-2">
            <div className="card-header">
                <Link to={'/tv/' + tvID + '/' + season.season_number}>
                    {
                        season.poster_path ?
                            <img className="img-fluid w-100" src={size + season.poster_path} title={season.name}
                                 alt={season.name}/>
                            :
                            <img className="img-fluid w-100" src={NOImage} title={season.name} alt={season.name}/>
                    }
                </Link>
            </div>
            <div className="card-movie-content">
                <div className="card-movie-content-head">
                    <Link to={'/tv/' + tvID + '/' + season.season_number}
                          className="d-block text-center">{season.name}</Link>
                    <div className="ratings"><span> {season.episode_count} </span> قسمت</div>
                </div>
            </div>
        </div>
    )
}