import {Component} from 'react';
import {Routes, Route} from "react-router-dom";
import authContext from "./context/auth";
import TheHeader from "./components/layout/TheHeader";
import TheFooter from "./components/layout/TheFooter";
import Home from "./page/Home";
import Login from "./page/Login";
import Register from "./page/Register";
import Profile from "./page/Profile";
import NoPage from "./page/noPage";
import Search from "./page/Search";
import Article from "./page/article/Article";
import AddArticle from "./page/article/Add";
import PageGenreListMovie from "./page/PageGenreListMovie";
import PageGenreListTv from "./page/PageGenreListTv";
import PageSingleMovie from "./page/PageSingleMovie";
import PageSingleTv from "./page/PageSingleTv";
import PageSingleSeason from "./page/PageSingleSeason";
import PageSingleEpisode from "./page/PageSingleEpisode";
import PageSinglePerson from "./page/PageSinglePerson";
import ArticleSingle from "./page/article/ArticleSingle";

let tokenLocalStorage = localStorage.getItem("token");

export default class App extends Component {
    constructor() {
        super();
        this.state = {
            isAuthentication: false,
            token: "",
        }
    }

    componentDidMount() {
        if (tokenLocalStorage) {
            this.setState({isAuthentication: true, token: tokenLocalStorage})
        } else {
            this.setState({isAuthentication: false, token: ""})
        }
    }

    render() {
        return (
            <authContext.Provider value={{
                isAuthentication: this.state.isAuthentication,
                token: this.state.token,
                login: () => {
                    this.setState({isAuthentication: true, token: localStorage.getItem("token")})
                },
                logout: () => {
                    this.setState({isAuthentication: false, token: ""})
                    localStorage.removeItem("token");
                }
            }}>
                <TheHeader/>
                <div id="MainContent" className="container">
                    <div className="row">
                        <Routes>
                            <Route>
                                <Route path="/" element={<Home/>}/>
                                <Route path="login" element={<Login/>}/>
                                {
                                    this.state.isAuthentication === false &&
                                    <>
                                        <Route path="register" element={<Register/>}/>
                                    </>
                                }
                                {
                                    this.state.isAuthentication === true &&
                                    <>
                                        <Route path="profile" element={<Profile/>}/>
                                        <Route path="add-article" element={<AddArticle/>}/>
                                    </>
                                }
                                <Route path="search" element={<Search/>}/>
                                <Route path="article" element={<Article/>}/>
                                <Route path="article/:id" element={<ArticleSingle/>}/>
                                <Route path="movie/:id" element={<PageSingleMovie/>}/>
                                <Route path="person/:id" element={<PageSinglePerson/>}/>
                                <Route path="tv/:id" element={<PageSingleTv/>}/>
                                <Route path="tv/:id/:season" element={<PageSingleSeason/>}/>
                                <Route path="tv/:id/:season/:episode" element={<PageSingleEpisode/>}/>
                                <Route path="genre-tv/:id" element={<PageGenreListTv/>}/>
                                <Route path="genre-movie/:id" element={<PageGenreListMovie/>}/>
                                <Route path="*" element={<NoPage/>}/>
                            </Route>
                        </Routes>
                    </div>
                </div>
                <TheFooter/>
            </authContext.Provider>
        )
    }
}