import {useState, useEffect, useContext} from "react";
import {Link, useParams} from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import authContext from "../../context/auth";

export default function ArticleSingle() {
    let authContextValue = useContext(authContext);
    let navigate = useNavigate();
    const article_id = useParams();
    const [edit, setEdit] = useState({
        editBox: false,
        editE: false,
        editEM: "",
        passwordEM: "",
        access: false,
    });
    const [article, setArticle] = useState([]);
    const [articleEdit, setArticleEdit] = useState({
        title: "",
        slug: "",
        description: "",
        content: "",
    });

    let localStorageArticles = localStorage.getItem("articles");
    let parseArticles = JSON.parse(localStorageArticles);

    useEffect(async () => {

        let article = parseArticles.find(
            (article) => article.slug === article_id.id
        );
        setArticle(article)
        setArticleEdit(article)
    }, [])

    const changeEditBox = () => {
        setEdit(previousState => {
            return {...previousState, editBox: !edit.editBox}
        })
    }

    const handlerTitleChange = event => {
        setArticleEdit(previousState => {
            return {...previousState, title: event.target.value}
        });
    }
    const handlerDescriptionChange = event => {
        setArticleEdit(previousState => {
            return {...previousState, description: event.target.value}
        });
    }
    const handlerContentChange = event => {
        setArticleEdit(previousState => {
            return {...previousState, content: event.target.value}
        });
    }
    const message = () => {
        setEdit(previousState => {
            return {...previousState, editE: true, editEM: "ویرایش با موفقیت انجام شد."}
        })
    }
    const slugify = (string) => {
        const a =
            "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
        const b =
            "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
        const p = new RegExp(a.split("").join("|"), "g");

        return (
            string
                .toString()
                .toLowerCase()
                .replace(/\s+/g, "-") // Replace spaces with -
                .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, "-and-") // Replace & with 'and'
                //.replace(/[^\w-]+/g, "") // Remove all non-word characters
                .replace(/--+/g, "-") // Replace multiple - with single -
                .replace(/^-+/, "") // Trim - from start of text
                .replace(/[~&\\;"',.<>#\s]/g, "") // Replace spaces ~ & \ ; " ' , . < > #
                .replace(/-+$/, "")
        ); // Trim - from end of text
    }

    const doEdit = event => {
        event.preventDefault();
        let index = parseArticles.findIndex(
            (article) => article.slug === article_id.id
        );
        parseArticles[index] = {
            title: articleEdit.title.trim(),
            slug: slugify(articleEdit.title),
            description: articleEdit.description.trim(),
            content: articleEdit.content.trim(),
        };
        let editArticle = JSON.stringify(parseArticles);
        localStorage.setItem("articles", editArticle);
        setArticle(parseArticles[index]);
        edit.editBox = false;
        navigate(`/article/${parseArticles[index].slug}`);
        setTimeout(message, 1000);
    }
    const doRemove = event => {
        let index = parseArticles.findIndex(
            (article) => article.slug === article_id.id
        );
        parseArticles.splice(index, 1);
        let removeArticle = JSON.stringify(parseArticles);
        localStorage.setItem("articles", removeArticle);
        navigate('/article');
    }
    return (
        <div id="Single">
            <div className="row">
                {
                    edit.editE === true &&
                    <div className="col-12" v-if="">
                        <div className="alert alert-success" role="alert">{edit.editEM}</div>
                    </div>
                }
                <div className="col-12">
                    <div className="card wrapper-text">
                        <div className="card-header text-center bg-color-primary-4 text-white">
                            <h2 className="m-0 p-0">{article.title}</h2>
                        </div>
                        <div className="card-body">
                            <p className="card-text">{article.content}</p>
                        </div>
                        <div className="card-footer text-center">
                            <Link to="/article" className="btn btn-success btn-sm me-1 text-white">
                                <span className="d-inline-flex align-middle mdi mdi-arrange-send-to-back me-1"/>مشاهده
                                همه نوشته ها
                            </Link>
                            {
                                authContextValue.isAuthentication === true &&
                                <>
                                    <div className="btn btn-secondary btn-sm me-1" onClick={changeEditBox}>ویرایش</div>
                                    <div className="btn btn-danger btn-sm" onClick={doRemove}>حذف</div>
                                </>
                            }

                        </div>
                    </div>
                </div>

                {
                    edit.editBox === true &&
                    <div className="col-12">
                        <div className="card mt-3">
                            <div className="card-header bg-color-primary-3 text-white text-center">
                                ویرایش سریع مقاله
                            </div>
                            <div className="card-body bg-light">
                                <form onSubmit={doEdit}>
                                    <div className="mb-3">
                                        <label className="form-label">عنوان مقاله</label>
                                        <input type="text" className="form-control" value={articleEdit.title}
                                               name="editTitle"
                                               onChange={handlerTitleChange}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">توضیحات مقاله</label>
                                        <textarea className="form-control" rows="2" value={articleEdit.description}
                                                  name="editDescription"
                                                  onChange={handlerDescriptionChange}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">متن کامل مقاله</label>
                                        <textarea className="form-control" rows="8" value={articleEdit.content}
                                                  name="editContent"
                                                  onChange={handlerContentChange}/>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-sm btn-secondary">
                                            <span className="d-inline-flex align-middle mdi mdi-content-save me-1"/>
                                            ثبت ویرایش مقاله
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                }
            </div>
        </div>
    )
}