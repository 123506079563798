import BioTv from "../components/section/BioTv";
import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import {apiKey, lang} from "../mixins";
import apiAxios from "../Api";
import SeasonTv from "../components/section/SeasonTv";


export default function PageSingleTv() {
    const [tv, setTv] = useState([]);
    const tv_id = useParams();

    useEffect(async () => {

        let query = `/tv/${tv_id.id}?api_key=${apiKey}&language=${lang}&language=${lang}`
        try {
            const request = await apiAxios.get(query)
            const response = request.data
            setTv(response);
        } catch (error) {
            console.log(error)
            console.log('error in catch fetchData PageSingleMovie getMovie');
        }
    }, [])


    return (
        <>
            <section id="PageSingleTv" className="col-12 wrapper-text">
                <BioTv tv={tv}/>
                <SeasonTv tv={tv} tvId={tv_id.id}/>
            </section>
        </>
    )
}